@import '~sass-mq';
@import 'styles/variables.scss';

.App {
  display: flex;
  flex-direction: column;
  height: 100%;

  .App-content {
    max-width: 700px;
    margin: 0 auto;
    flex: 1 0 auto;

    .App-linkMapWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 60px;

      .App-findingLinkSpinner {
        fill: #999;
        width: 55px;
      }
    }

    .App-linkMapResult {
      margin-top: 30px;
    }

    .App-linkMapResultError {
      color: $clrError;
    }

    .App-linkMapResultDegOfSep {
      color: $clrOrange;
      font-weight: bold;
      font-size: larger;
      border-bottom: 1px dashed #ccc;
    }

    // TODO: variablize these myriad granulan header breakpoints
    header {
      position: relative;
      min-height: 360px;
      margin-bottom: 30px;

      h1 {
        display: flex;
        align-items: center;
        padding: 0 0 5px 0;
        border-bottom: 1px solid #999;

        .logo {
          width: 20px;
          margin-right: 8px;
          margin-left: 5px;

          @include mq(0, 650px) {
            width: 15px;
          }

          @include mq($until: 'mobile') {
            width: 18px;
          }

          @include mq(0, 'mobileSmall') {
            width: 15px;
          }
        }

        .logoText {
          letter-spacing: -2px;
          font-size: 2.3rem;
          color: #333;

          @include mq(0, 735px) {
            font-size: 1.9rem;
          }

          @include mq(0, 650px) {
            font-size: 1.7rem;
          }

          @include mq(0, 600px) {
            font-size: 1.55rem;
          }

          @include mq($until: 'mobile') {
            font-size: 2.1rem;
          }

          @include mq(0, 'mobileSmall') {
            font-size: 1.7rem;
          }

          @include mq(0, 400px) {
            font-size: 1.6rem;
          }

          @include mq(0, 380px) {
            font-size: 1.5rem;
          }

          @include mq(0, 330px) {
            font-size: 1.4rem;
          }
        }
      }

      .headerImage {
        position: absolute;
        top: -10px;
        right: 10px;
        transform: rotate(-2deg);

        @include mq(0, 660px) {
          width: 280px;
          right: 20px;
        }

        @include mq($until: 'mobile') {
          position: relative;
          left: -37px;
          max-width: 305px;
          width: 100%;
          margin: 0 auto;
          display: block;
          transform: rotate(2deg);
          margin-top: 40px;
        }

        @include mq(0, 450px) {
          width: 60%;
          left: -20px;
          margin-top: 35px;
        }
      }

      p {
        font-size: 1.35rem;
        font-family: Georgia;
        font-style: italic;
        line-height: 1.3;
        padding-right: 330px;

        &:first-of-type {
          margin-top: 20px;

          @include mq(0, 450px) {
            margin-top: 15px;
          }
        }

        &:last-of-type {
          margin-top: 15px;
          margin-bottom: 30px;
        }

        @include mq(0, 690px) {
          font-size: 1.15rem;
        }

        @include mq(0, 620px) {
          font-size: 1.05rem;
        }

        @include mq(0, 580px) {
          font-size: 1rem;
        }

        @include mq($until: 'mobile') {
          padding-right: 0;
        }
      }
    }
  }

  footer {
    text-align: center;
    font-size: 0.85rem;
    flex-shrink: 0;

    .copyright a {
      margin-left: 4px;
    }
  }  
}