@import 'styles/variables.scss';

.Player {
  border-radius: 50%;
  border: 1px dashed $clrOrange;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;

  .PlayerAvatar {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
}