.LinkMap {
  display: flex;
  align-items: center;
  flex-direction: column;

  // & > * {
  //   flex-shrink: 0;
  // }

  .LinkMap-playerWrap {
    display: flex;
    align-items: center;
    flex-direction: column;    

    .LinkMap-playerWrapNameArea {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      // todo: put a max width here and width: min content on break
      // also on tight mq sizes;

      .LinkMap-playerWrapNameAreaInner {
        display: flex;
        align-items: center;
        justify-content: center;

        .LinkMap-playerWrapNum {
          margin-right: 5px;
          padding-right: 5px;
          border-right: 1px solid #000;
          align-self: start;
          height: 100%;
        }
      }

      &.LinkMap-playerWrapNameAreaLongAssName .LinkMap-playerWrapName {
        width: min-content;
      }
    }
  }
}