@import 'styles/variables.scss';

.Team {
  border-radius: 50%;
  border: 1px dashed $clrYellow;
  padding: 8px;
  width: 75px;
  height: 75px;

  .Team-innerWrap {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
  }
}