.PlayerAvatar {
  border-radius: 50%;
  background-size: cover;
  background-position: center 3px;
  background-repeat: no-repeat;
  border: 1px dashed #BBB;
  background-color: hsl(199, 43%, 94%);

  &.PlayerAvatar-defaultAvatar {
    background-size: 35%;
    background-position: center;
  }
}