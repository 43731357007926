.SelectedPlayer {
  display: inline-flex;
  align-items: center;
  position: relative;

  .PlayerAvatar {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    flex-shrink: 0;
  }

  .SelectedPlayer-btnRemove {
    position: absolute;
    right: -15px;
    top: -10px;
    border: 1px solid #CCC;
    border-radius: 50%;
    width: 20px;
    height: 20px;

    svg {
      fill: #000;
      position: relative;
      top: -1px;
      left: -1px;
    }
  }
}