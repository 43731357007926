// http://devfacts.com/media-queries-breakpoints-2020/
$mq-breakpoints: (
  mobileSmall: 440px,
  mobile: 570px,
);

$clrBackground: rgb(251,251,251);
$clrText: rgb(51,51,51);
$clrLink: #1a99aa;
$clrOrange: #df6c4f;
$clrYellow: #ecd06f;
$clrError: red;