@import './variables.scss';
@import './components/buttons.scss';

html {
  box-sizing: border-box;
  font-size: 18px;
  font-family: sans-serif;
  color: $clrText;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 20px;
}

input[type=text], textarea {
  border: 1px solid #000;
  border-radius: 3px;
  width: 100%;
  padding: 7px 10px;
  line-height: 1.25rem;
  transition: all 0.30s ease-in-out;
  outline: none;
  font-size: 1rem;
  background-color: $clrBackground;
}

a {
  color: $clrLink;
}