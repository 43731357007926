html {
  height: 100%;
  margin-left: calc(100vw - 100%);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(251,251,251);
  min-height: 100%;
  margin: 0;
  display: grid;
}

#root {
  height: 100%;
}